import React from 'react';
import Form from '@rjsf/material-ui';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import ApiService from './ApiService';
import configSchema from './schema/configuration.schema.json';
import configUISchema from './schema/configuration.ui.schema.json';
import createConfigUISchema from './schema/create-configuration.ui.schema.json';
import fileFormatSchema from './schema/fileformat.schema.json';
import fileFormatUISchema from './schema/fileformat.ui.schema.json';
import protocolSchema from './schema/protocol.schema.json';
import protocolUISchema from './schema/protocol.ui.schema.json';
import mappingSchema from './schema/mapping.schema.json';
import mappingUISchema from './schema/mapping.ui.schema.json';
import PasswordWidget from './PasswordWidget';
import AutoCompleteWidget from "./AutoCompleteWidget";
import CustomButtonWidget from "./CustomButtonWidget";
import {eventBus} from "./eventBus";
import {CircularProgress} from '@material-ui/core';
import validator from '@rjsf/validator-ajv8';

const widgets = {
    PasswordWidget: PasswordWidget
};

const mappingWidgets = {
    AutoCompleteWidget: AutoCompleteWidget,
    PasswordWidget: PasswordWidget
}

const configurationWidgets = {
    CustomButtonWidget: CustomButtonWidget
}

export default class ConfigurationForm extends React.Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
            tabPos: 0,
            configCreated: false,
            config: {
                id: this.props.configId || ''
            },
            isLoaded: false,
            configUISchema: createConfigUISchema,
            numberOfDeliveredEdited: false,
        };
        this.refreshConfig = this.refreshConfig.bind(this);
        this.handleError = this.handleError.bind(this);
        this.handleBaseSubmit = this.handleBaseSubmit.bind(this);
        this.createConfiguration = this.createConfiguration.bind(this);
        this.saveBaseChanges = this.saveBaseChanges.bind(this);
        this.saveProtocol = this.saveProtocol.bind(this);
        this.saveFileFormat = this.saveFileFormat.bind(this);
        this.saveMapping = this.saveMapping.bind(this);
        this.isDeliveredEdited = this.isDeliveredEdited.bind(this);
    }

    async componentDidMount() {
        this._isMounted = true;
        this.setListeners();
        await this.refreshConfig();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    async refreshConfig() {
        if (this.state.config.id) {
            if(this._isMounted){
                this.setState({
                    isLoaded: false
                })
            }
            const config = await ApiService.getConfig(this.state.config.id);
            if(this._isMounted){
                this.setState({
                    configCreated: true,
                    configUISchema: configUISchema,
                    config: config,
                })
            }
            if(this.state.config.sequenceNumberId){
                await this.getDeliveriesBySequenceNumber();
            }else{
                if(this._isMounted){
                    this.setState((prevState)=>{
                        return{
                            ...prevState,
                            isLoaded: true
                        }
                    })
                }
            }
        }
    }

    handleError(err) {
        if (this.props.onError) {
            this.props.onError(err);
        }
    }

    async handleBaseSubmit(data) {
        if(!this.state.configCreated) {
            this.createConfiguration(data);
        } else {
            this.saveBaseChanges(data);
        }
    }

    async createConfiguration(data){
        if (!this.state.configCreated) {
            try {
                const res = await ApiService.createConfig(data.formData.name);
                if (res && res.id) {
                    let config = this.state.config;
                    config.id = res.id;
                    this.setState({
                        config: config,
                        configCreated: true,
                        configUISchema: configUISchema
                    });
                }
                await this.refreshConfig();
            } catch (err) {
                this.handleError(err);
            }
        }
    }

    async saveBaseChanges(data) {
        try {
            if (this.state.configCreated) {
                let changed = false;
                if (data.formData.active !== this.state.config.active) {
                    await ApiService.toggleConfigActive(this.state.config.id, data.formData.active);
                    changed = true;
                }
                if (data.formData.lockable !== this.state.config.lockable) {
                    await ApiService.toggleConfigLockable(this.state.config.id, data.formData.lockable);
                    changed = true;
                }
                if (data.formData.realtime !== this.state.config.realtime) {
                    await ApiService.setRealtime(this.state.config.id, data.formData.realtime);
                    changed = true;
                }
                if (data.formData.sequenceNumberId !== this.state.config.sequenceNumberId) {
                    await ApiService.updateSequenceNumber(this.state.config.id, data.formData.sequenceNumberId);
                    changed = true;
                }
                if (data.formData.name !== this.state.config.name) {
                    await ApiService.renameConfig(this.state.config.id, data.formData.name);
                    changed = true;
                }
                if (data.formData.filterExpression !== this.state.config.filterExpression) {
                    await ApiService.setFilterExpression(this.state.config.id, data.formData.filterExpression);
                    changed = true;
                }
                if (data.formData.interval !== this.state.config.interval) {
                    await ApiService.setScheduleInterval(this.state.config.id, data.formData.interval);
                    changed = true;
                }
                if (data.formData.numberOfDeliveries !== this.state.config.numberOfDeliveries) {
                    await ApiService.setConfigNumberOfDeliveries(this.state.config.id, data.formData.numberOfDeliveries);
                    changed = true;
                }
                if (changed) {
                    await this.refreshConfig();
                }
            }
        } catch (err) {
            this.handleError(err);
        }
    }

    async saveProtocol(evt) {
        if (this.state.configCreated) {
            try {
                await ApiService.saveProtocol(this.state.config.id, evt.formData);
                await this.refreshConfig();
            } catch (err) {
                this.handleError(err);
            }
        }
    }

    async saveFileFormat(evt) {
        if (this.state.configCreated) {
            try {
                await ApiService.saveFileFormat(this.state.config.id, evt.formData);
                await this.refreshConfig();
            } catch (err) {
                this.handleError(err);
            }
        }
    }

    async saveMapping(evt) {
        if (this.state.configCreated) {
            try {
                await ApiService.saveMapping(this.state.config.id, evt.formData);
                await this.refreshConfig();
            } catch (err) {
                this.handleError(err);
            }
        }
    }

    isDeliveredEdited(evt){
        if((evt.formData.numberOfDeliveredItemsSeq !== this.state.config.numberOfDeliveredItemsSeq) || (evt.formData.numberOfDeliveriesSeq !== this.state.config.numberOfDeliveriesSeq)){
            eventBus.dispatch("numberEdited",{
                numberOfDeliveredItemsSeq: evt.formData.numberOfDeliveredItemsSeq ? evt.formData.numberOfDeliveredItemsSeq : 0,
                numberOfDeliveriesSeq: evt.formData.numberOfDeliveriesSeq ? evt.formData.numberOfDeliveriesSeq : 0,
                sequenceNumberId: this.state.config.sequenceNumberId
            })
            if(this._isMounted){
                this.setState((prevState)=>{
                    return{
                        ...prevState,
                        config:{
                            ...prevState.config,
                            numberOfDeliveredItemsSeq: evt.formData.numberOfDeliveredItemsSeq,
                            numberOfDeliveriesSeq: evt.formData.numberOfDeliveriesSeq
                        },
                        numberOfDeliveredEdited: true
                    }
                })
            }
        }
        else if(this.state.numberOfDeliveredEdited){
            eventBus.dispatch("disableButton");
            if(this._isMounted){
                this.setState((prevState)=>{
                    return{
                        ...prevState,
                        numberOfDeliveredEdited: false
                    }
                })
            }
        }
    }

    async getDeliveriesBySequenceNumber(){
        const data = await ApiService.getDeliveriesBySequenceNumber(this.state.config.sequenceNumberId);
            if(this._isMounted){
                this.setState((prevState)=>{
                    return{
                        ...prevState,
                        config:{
                            ...prevState.config,
                            numberOfDeliveriesSeq: data.numberOfDeliveries,
                            numberOfDeliveredItemsSeq: data.numberOfDeliveredItems
                        },
                        isLoaded: true
                    }
                })
            }
    }

    setListeners(){
        eventBus.on("successfullyEdited",(data)=>{
            if(this._isMounted){
                this.setState((prevState)=>{
                    return{
                        ...prevState,
                        config:{
                            ...prevState.config,
                            numberOfDeliveriesSeq: data.numberOfDeliveries,
                            numberOfDeliveredItemsSeq: data.numberOfDeliveredItems
                        },
                        numberOfDeliveredEdited: false
                    }
                })
            }
        })
    }


    render() {
            if(this.state.isLoaded || !this.props.configId){
                return <div className="ConfigurationForm">
                    <Tabs value={this.state.tabPos} onChange={(evt, newValue) => {this.setState({tabPos: newValue})}}>
                        <Tab label="Basic"/>
                        <Tab label="Mapping" disabled={!this.state.configCreated}/>
                        <Tab label="File Format" disabled={!this.state.configCreated}/>
                        <Tab label="Protocol" disabled={!this.state.configCreated}/>
                    </Tabs>

                    <TabPanel value={this.state.tabPos} index={0}>
                        <Form schema={configSchema}
                              uiSchema={this.state.configUISchema}
                              widgets={configurationWidgets}
                              formData={this.state.config}
                              idPrefix="configuration"
                              validator={validator}
                              onChange={this.isDeliveredEdited}
                              onSubmit={this.handleBaseSubmit}>
                            <Button type="submit" variant="contained" color="primary">{this.state.configCreated ? 'Save' : 'Create' }</Button>
                        </Form>
                    </TabPanel>
                    <TabPanel value={this.state.tabPos} index={1}>
                        <Form schema={mappingSchema}
                              uiSchema={mappingUISchema}
                              widgets={mappingWidgets}
                              validator={validator}
                              formData={this.state.config.mapping || {}}
                              idPrefix="mapping"
                              onSubmit={this.saveMapping}>
                            <Button type="submit" variant="contained" color="primary">Save</Button>
                        </Form>
                    </TabPanel>
                    <TabPanel value={this.state.tabPos} index={2}>
                        <Form schema={fileFormatSchema}
                              uiSchema={fileFormatUISchema}
                              widgets={widgets}
                              formData={this.state.config.fileFormat || {}}
                              idPrefix="fileformat"
                              validator={validator}
                              onSubmit={this.saveFileFormat}>
                            <Button type="submit" variant="contained" color="primary">Save</Button>
                        </Form>
                    </TabPanel>
                    <TabPanel value={this.state.tabPos} index={3}>
                        <Form schema={protocolSchema}
                              uiSchema={protocolUISchema}
                              widgets={widgets}
                              formData={this.state.config.protocol || {}}
                              idPrefix="protocol"
                              validator={validator}
                              onSubmit={this.saveProtocol}>
                            <Button type="submit" variant="contained" color="primary">Save</Button>
                        </Form>
                    </TabPanel>
                </div>;

            }
            else if(this.props.configId) {
                return <div style={{display:"flex",justifyContent: "center"}}><CircularProgress size={70}></CircularProgress></div>;
            }
    }
}


function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-prevent-tabpanel-${index}`}
            aria-labelledby={`scrollable-prevent-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography component="span">{children}</Typography>
                </Box>
            )}
        </div>
    );
}


TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};